.QR {
  border-collapse: collapse;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QR h1 {
  align-self: start;
  margin-bottom: 50px;
}

.QRgrid {
  margin-top: 50px;
}

.QR .QRgrid {
  display: grid;
  grid-row-gap: var(--rowGap);
  /*grid-row-gap: 10mm; /* Space between rows*/
}

.QR .row {
  /*height: 33.9mm; /* Avery */
  grid-column-gap: 8mm; /* Space between cols*/
  display: grid;
}

.QR .cell {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-self: center;
  grid-column-gap: 10px;
  height: 80px; /* DECAdry */
  margin: 2mm;
}

/*
.QR .cell:nth-child(3)  {
  border: dashed 1px;
}
/**/
.QR .cell div.QRright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 150px;
  line-height: 2mm;
}

.QR .cell span.QRvalue {
  font-size: 4.5mm;
  line-height: 5mm;
  text-align: center;
}

.QR .cell span.QRbrand {
  position: relative;
  float: bottom;
}

.QR .cell span.QRbrand img {
  height: 10px;
  bottom: 0;
}

.QR .MuiFormControl-root-1 {
  width: 100px;
  margin: 0 20px 20px 0;
}


@media print
{
  .no-print, no-print * {
    display: none !important;
  }
  .App-header, .Menu {
    display: none !important;
  }
  .QRgrid{
    position: fixed;
    top: 0;
    left: 0;
    /*margin: 12mm 6mm 12mm 2mm; */
    /*margin: 14mm 6mm 12mm 4mm; */ 
    /*margin: 11mm 4mm 12mm 4mm;  /* Avery */
    /* margin: 8.2mm 0mm 8mm -4.5mm; /* DECAdry */
    margin: var(--printMarginTop) var(--printMarginLeft);
  }
}
