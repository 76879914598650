@import url('https://fonts.googleapis.com/css?family=Work+Sans:600');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,600&display=swap');

@media print
{
  @page {
    size: 210mm 297mm;
  }
  html, body, * { 
    height: initial !important; 
    overflow: initial !important; 
  }
  .noPrint, .noPrint * {
    display: none !important;
  }
}
